import { Box, Columns, Image, Tag } from 'react-bulma-components'

const Edificio = ({ edificio, urbanismo }) => {
  return (
    <>
    <Box>
      <Columns className='is-vcentered'>
        <Columns.Column>
          <Image src={`https://www1.sedecatastro.gob.es/Cartografia/GeneraGraficoParcela.aspx?refcat=${edificio.id}&del=${edificio.del_}&mun=${edificio.mun}&AnchoPixels=480&AltoPixels=480`} alt='croquis' />
        </Columns.Column>
        <Columns.Column>
          <Image src={edificio.url_fachada} alt='foto fachada' />
        </Columns.Column>
      </Columns>

      <Columns className='is-vcentered is-centered is-mobile'>
        {urbanismo.fotos.map(foto =>
          <Columns.Column key={foto} className='is-one-fifth-tablet is-half-mobile'>
            <Image src={foto} alt='foto' />
          </Columns.Column>
        )}
      </Columns>

      <Tag.Group className='are-medium'>
        <Tag>{edificio.id}</Tag>
        <Tag color='white' style={{height: 'auto', whiteSpace: 'unset'}}>{edificio.localizacion}</Tag>
        <Tag>{edificio.superficie}m²</Tag>
        <Tag className='is-link is-light'>
          <a href={edificio.url_pdf} target='_blank' rel="noreferrer" >
            PDF Distribución por plantas
          </a>
        </Tag>
        <Tag className='is-link is-light'>
          <a href={`https://www-s.madrid.es/VSURB_RSURBA/api_rsurba/v1/descargas/getDocumento?tipoDoc=ANEDIF&docId=${urbanismo.manzana}`} target='_blank' rel="noreferrer" >
            PDF manzana
          </a>
        </Tag>
        <Tag className='is-link is-light'>
          <a href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${edificio.lat}%2C${edificio.lon}`} target='_blank' rel="noreferrer" >
            Google Streetview
          </a>
        </Tag>
      </Tag.Group>
    </Box>
    </>
  )
}

export default Edificio