import { useState, useEffect } from 'react'
import request from '../helpers/request'
import { Notification } from 'react-bulma-components'
import Cargando from './Cargando'
import Edificio from './Edificio'
import ListaInmuebles from './ListaInmuebles'
import Inmueble from './Inmueble'
import arr_eq from '../helpers/arr_eq'

const DEFAULT_COORDS = [40.417, -3.703]

const Catastro = ({ pos, setPos, popped }) => {
  const [edificio, setEdificio] = useState({})
  const [inmuebles, setInmuebles] = useState([])
  const [urbanismo, setUrbanismo] = useState({fotos: []})
  const [state, setState] = useState('')

  useEffect(() => {
    if ((!edificio.id && !arr_eq(pos, DEFAULT_COORDS)) || (edificio.id && !arr_eq(pos, [edificio.lat, edificio.lon]))) {
      return request(
        'find_catastro',
        { lat: pos[0], lon: pos[1] },
        setState,
        (e => {
          setEdificio(e)
          setInmuebles(e.inmuebles)
          window.history.pushState(e.id, '', e.id)

          request(
            'find_urbanismo',
            { lat: e.lat, lon: e.lon },
            setState,
            (u => setUrbanismo(u))
          )
        })
      )
    }
  // eslint-disable-next-line
  }, [pos])

  useEffect(() => {
    if (window.location.pathname !== '/') {
      return request(
        `edificio/${window.location.pathname.substring(1, 15)}`,
        {},
        setState,
        (e => {
          setEdificio(e)
          setInmuebles(e.inmuebles)
          setPos([e.lat, e.lon])

          request(
            'find_urbanismo',
            { lat: e.lat, lon: e.lon },
            setState,
            (u => setUrbanismo(u))
          )
        })
      )
    } else if (edificio.id) {
      // Went back to main page
      setPos(DEFAULT_COORDS)
    }
  // eslint-disable-next-line
  }, [popped])

  if (state === 'loading') {
    return <Cargando />
  } else if (state === 'error') {
    return (
      <Notification color='danger'>
        Error
      </Notification>
    )
  } else {
    return (
      <>
      <Edificio edificio={edificio} urbanismo={urbanismo} />

      {inmuebles.length === 1 ?
        <Inmueble inmueble={inmuebles[0]} /> :
        <ListaInmuebles inmuebles={inmuebles} popped={popped} />
      }
      </>
    )
  }
}
 
export default Catastro