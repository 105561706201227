import { Button, Form, Icon } from 'react-bulma-components'
import { MdSearch, MdMyLocation } from 'react-icons/md'
import { useState } from 'react'

const SearchBar = ({ setPos }) => {
  const [search, setSearch] = useState('')

  const searchAddress = () =>
    fetch(`https://cartociudad.es/geocoder/api/geocoder/find?q=${search}, Madrid`)
    .then(data => data.json())
    .then(data =>
      setPos([data.lat, data.lng])
    )
    .catch(() => {})

  const getLocation = () =>
    navigator.geolocation.getCurrentPosition(pos =>
      setPos([pos.coords.latitude, pos.coords.longitude])
    )

  return (
    <Form.Field kind='group' size='medium'>
      <Form.Control fullwidth>
        <Form.Input
          placeholder='calle nsq'
          value={search}
          onChange={e => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              searchAddress()
          }}
        />
      </Form.Control>
      <Form.Control>
        <Button onClick={searchAddress}>
          <Icon>
            <MdSearch/>
          </Icon>
        </Button>
      </Form.Control>
      <Form.Control>
        <Button onClick={getLocation}>
          <Icon>
            <MdMyLocation/>
          </Icon>
        </Button>
      </Form.Control>
    </Form.Field>
  )
}

export default SearchBar
