import { MapContainer, Marker, TileLayer, WMSTileLayer } from 'react-leaflet';
import {Icon} from 'leaflet'
import Catastro from './components/Catastro'
import SearchBar from './components/SearchBar'
import { useState, useEffect, useCallback } from 'react'
import { Section, Block, Columns } from 'react-bulma-components'
import arr_eq from './helpers/arr_eq'
import 'leaflet/dist/leaflet.css';

const DEFAULT_COORDS = [40.417, -3.703]
const DEFAULT_ZOOM = 13
const MAX_ZOOM = 21

const App = () => {
  const [pos, setPos] = useState(DEFAULT_COORDS)
  const [popped, setPopped] = useState('')
  const [map, setMap] = useState(null)

  useEffect(() => {
    if (!arr_eq(pos, DEFAULT_COORDS))
      map?.setView(pos, MAX_ZOOM, { animate: true })
    else
      map?.setView(pos, DEFAULT_ZOOM, { animate: true })
  }, [pos, map])

  window.addEventListener('popstate', e => setPopped(e.state));

  const onClick = useCallback(e => {
    setPos([e.latlng.lat, e.latlng.lng])
  }, [])

  useEffect(() => {
    map?.on('click', onClick)
  }, [map, onClick])

  return (<>
    <MapContainer
      center={DEFAULT_COORDS}
      zoom={DEFAULT_ZOOM}
      zoomControl={false}
      style={{height: '75vh', cursor: 'auto'}}
      ref={setMap}
    >
      <TileLayer
        url='https://api.maptiler.com/maps/bright-v2/256/{z}/{x}/{y}{r}.png?key=62fWxjuKZdoP6vlFjq0a'
        maxZoom={MAX_ZOOM}
      />
      <WMSTileLayer
        url={'https://www1.sedecatastro.gob.es/cartografia/GeneraMapa.aspx'}
        layers={'CONSTRU,PARCELA,MASA,ELEMLIN,EJES,LIMITES,TEXTOS'}
        format={'image/png'}
        transparent={true}
        maxZoom={MAX_ZOOM}
      />
      {!arr_eq(pos, DEFAULT_COORDS) && <Marker position={pos} icon={new Icon({iconUrl: 'marker.svg', iconAnchor: [25, 55]})} />}
    </MapContainer>

    <div style={{position: 'absolute', top: 0, zIndex: 400, margin: 20}}>
      <SearchBar setPos={setPos} />
    </div>

    <Section>
      <Block>
        <Columns className='is-centered'>
          <Columns.Column className='is-three-quarters-desktop is-two-thirds-fullhd'>
            {!arr_eq(pos, DEFAULT_COORDS) || window.location.pathname !== '/' ? <Catastro pos={pos} setPos={setPos} popped={popped} /> : ''}
          </Columns.Column>
        </Columns>
      </Block>
    </Section>

    <Section />
    <Section />

  </>)
}

export default App
