import { useState, useEffect } from 'react'
import request from '../helpers/request'
import { Box, Table, Notification, Tag } from 'react-bulma-components'
import Cargando from './Cargando'

const Inmueble = ({ inmueble }) => {
  const [construccion, setConstruccion] = useState([])
  const [state, setState] = useState('')

  useEffect(() => {
    if (inmueble.construccion) {
      setConstruccion(inmueble.construccion)
    } else {
      return request(
        `inmueble/${inmueble.id}`,
        {},
        setState,
        (i => setConstruccion(i.construccion))
      )
    }
  }, [inmueble])

  if (state === 'loading') {
    return <Cargando />
  } else if (state === 'error') {
    return (
      <Notification color='danger'>
        Error
      </Notification>
    )
  } else {
    return (
      <>
      <Box>
        <Tag.Group className='are-medium'>
          <Tag>{inmueble.id}</Tag>
          <Tag color='white' style={{height: 'auto', whiteSpace: 'unset'}}>{inmueble.localizacion}</Tag>
          <Tag>{inmueble.uso}</Tag>
          <Tag>{inmueble.superficie}m²</Tag>
          <Tag>{inmueble.participacion}%</Tag>
          <Tag>{inmueble.año}</Tag>
          <Tag className='is-link is-light'>
            <a href={`https://www1.sedecatastro.gob.es/CYCBienInmueble/SECImprimirCroquisYDatos.aspx?del=${inmueble.del_}&mun=${inmueble.mun}&refcat=${inmueble.id}`} target='_blank' rel="noreferrer" >
              PDF Consulta descriptiva y gráfica
            </a>
          </Tag>
        </Tag.Group>

        <Table.Container>
          <Table size='fullwidth'>
            <thead>
              <tr>
                <td>Uso</td>
                <td>Escalera</td>
                <td>Planta</td>
                <td>Puerta</td>
                <td>Superficie</td>
                <td>Tipo reforma</td>
                <td>Fecha reforma</td>
              </tr>
            </thead>
            <tbody>
              {construccion.map((c, i) => (
                <tr key={i}>
                  <td>{c[0]}</td>
                  <td>{c[1]}</td>
                  <td>{c[2]}</td>
                  <td>{c[3]}</td>
                  <td>{c[4]}m²</td>
                  <td>{c[5]}</td>
                  <td>{c[6]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Table.Container>
      </Box>
      </>
    )
  }
}

export default Inmueble