import { Box, Columns, Progress } from 'react-bulma-components'

const Cargando = () => {
  return (
    <Box>
      <Columns>
        <Columns.Column narrow={true} className='has-text-centered' >
            Cargando...
        </Columns.Column>
        <Columns.Column>
          <Progress color='info' />
        </Columns.Column>
      </Columns>
    </Box>
  )
}

export default Cargando