import axios from 'axios'

const request = (endpoint, params, setState, setData) => {
  setState('loading')

  const source = axios.CancelToken.source()
  axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    params: params,
    cancelToken: source.token
  })
  .then(response => {
    setState('ok')
    setData(response.data)
  })
  .catch(err => {
    if (!axios.isCancel(err))
      setState('error')
  })

  return () => source.cancel()
}

export default request
