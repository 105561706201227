import { useState, useRef, useEffect } from 'react'
import { Columns, Panel, Table } from 'react-bulma-components'
import Inmueble from './Inmueble'

const ListaInmuebles = ({ inmuebles, popped }) => {
  const [inmueble, setInmueble] = useState(null)
  const ref = useRef(null)

  useEffect(() => {
    const id = window.location.pathname.substring(1)
    if (id.length === 20) {
      setInmueble(inmuebles.find(i => i.id === id))
    }
  // eslint-disable-next-line
  }, [popped])

  useEffect(() => {
    if (inmueble) {
      ref.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [inmueble])

  return (
    <>
    <Panel>
      <Panel.Header>
        <Columns breakpoint='mobile'>
          <Columns.Column narrow={true}>
            {inmuebles.length} inmuebles
          </Columns.Column>
          <Columns.Column>
          </Columns.Column>
          <Columns.Column narrow={true}>
            {inmuebles.map(i => i.superficie).reduce((a, b) => a+b, 0)}m²
          </Columns.Column>
        </Columns>
      </Panel.Header>

      <Table.Container>
        <Table hoverable={true} size='fullwidth'>
          <thead>
            <tr>
              <td>Referencia catastral</td>
              <td>Localización</td>
              <td>Uso principal</td>
              <td>Superficie</td>
              <td>Participación</td>
              <td>Año de construcción</td>
            </tr>
          </thead>
          <tbody>
            {inmuebles.map(i => (
              <tr
                key={i.id}
                className={i.id === inmueble?.id ? 'is-selected' : ''}
                onClick={() => {
                  setInmueble(i)
                  window.history.pushState(i.id, '', i.id)
                }}
              >
                <td>{i.id}</td>
                <td>{i.localizacion}</td>
                <td>{i.uso}</td>
                <td>{i.superficie}m²</td>
                <td>{i.participacion}%</td>
                <td>{i.año}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Table.Container>
    </Panel>

    <div ref={ref}>
      {inmueble?.id ? <Inmueble inmueble={inmueble} /> : ''}
    </div>

    </>
  )
}

export default ListaInmuebles